.btn-primary {
    background-color: $primary;
    color: $dark;
    font-weight: 700;
    text-transform: uppercase;
    border: 0;
    padding: .75rem 1.5rem;

    &:hover, &:focus {
        background-color: $primary;
        color: $dark;
    }
}
