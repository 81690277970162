@import "colors.scss";

html, body {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    line-height: 25px;
    webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}

img {
    max-width: 100%;
    transition: all .3s ease-in-out;
}

a {
    transition: all .3s ease-in-out;
    text-decoration: none !important;

    &:hover {
        color: $primary !important;
    }
}

// BANNER
.banner-section {
    color: $light;
    background-image: url(../../img/bg-slide.jpg);
    background-size: cover;
    background-position: center;
    padding-top: 8rem;
    padding-bottom: 2rem;
    text-shadow: 5px 5px rgba($dark, 0.1);
}

// NAVBAR
.navbar {
    background-color: transparent;
    transition: all .3s ease-in-out;
    
    @media (min-width:992px){
        padding: 1rem;
    }

    @media (max-width:480px){
        background-color: rgba($light, 0.2);
    }

    .navbar-nav {
        .nav-link {
            color: $light;
            font-weight: 500;

            @media (min-width:992px) {
                padding-right: 1rem;
                padding-left: 1rem;
            }

            &:hover {
                color: $primary !important;
            }
        }
        .active {
            .nav-link {
                color: $primary !important;
            }
        }
    }

    .navbar-toggler {
        padding: 0 .5rem;
        margin-top: .5rem;
    }

    .navbar-toggler-icon {
        i {
            color: $light;
            vertical-align: middle;
        }
    }

    &.scrolled {
        background-color: $light;
        box-shadow: 1px 1px 8px rgba($dark, 0.1);

        .navbar-nav {
            .nav-link {
                color: $dark;
            }
        }
    }
}

// CAROUSEL
.carousel-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
        content: '';
        background-color: $overlay;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .carousel-caption {
        bottom: 30%;

        @media (max-width: 480px){
            bottom: 25%;
        }

        h5 {
            font-size: 70px;
            font-size: 700;
            margin-bottom: 2rem;
            @media (max-width: 480px){
                font-size: 28px;
                margin-bottom: 1rem;
            }
        }
        p {
            font-size: 18px;
            line-height: 30px;
            @media (max-width: 480px){
                font-size: 14px;
                line-height: 25px;
            }
        }
        .btn {
            margin-top: 2rem;
            @media (max-width: 480px){
                margin-top: 1rem;
            }
        }
    }
}

// SECTION
.section {
    padding-top: 100px;
    // padding-bottom: 100px;
    @media (max-width: 480px){
        padding-top: 50px;
    }   

    .title-section {
        font-size: 64px;
        line-height: 60px;

        @media (max-width: 800px){
            font-size: 48px;
        }

        @media (max-width: 480px){
            font-size: 28px;
            line-height: 30px;
        }
    }
    .badge-section {
        font-size: 16px;
        border-radius: 0;
        margin-bottom: 1rem;
    }
    .info-col {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

// ABOUT SECTION
.img-about-section {
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: 10px 10px $primary;
}

// INFO SECTION
.info-section {
    .card-info {
        position: relative;
        overflow: hidden;
        border-radius: 16px;
        margin-bottom: 2rem;

        &:hover {
            .caption {
                padding-top: 2rem;
                height: 100%;
                transition: all .3s ease-in-out;
            }
        }

        .caption {
            color: $light;
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
            position: absolute;
            bottom: 0;
            height: 80px;
            padding: 1rem 1rem .5rem;
            transition: all .3s ease-in-out;

            a {
                color: $light;
            }
        }
    }
}

.info-detail {
    padding-top: 50px;

    .sidebar {
        a {
            color: $dark;
        }
        
        .list-media {
            .media {
                margin-bottom: 1.5rem;
            }
        }
    }
}

// FOOTER
.footer {
    background-color: $dark;
    color: $gray;
    font-size: 14px;
    margin-top: 5rem;

    .title-footer {
        color: $light;
    }

    a {
        color: $gray;
    }

    .copyright {
        padding-bottom: 2rem;
        margin-top: 5rem;
    }
}

.socmed {
    a {
        color: $gray;
        font-size: 18px;
    }
}
