
.gallery-main {
    .fancybox {
        display: block;
        margin-bottom: 30px;

        .thumb {
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 250px;

            @media (max-width: 768px) {
                height: 150px;
            }

            @media (max-width: 480px) {
                height: 120px;
            }
        }
    }
}